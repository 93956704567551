import React, { useState, useContext, useEffect } from 'react';
import Api from '../../../service/api';

//resources
import Button from '../../../components/Button';

//styles
import InfoContainer from './styles.js';

//contexts
import TokenContext from '../../../contexts/Token/TokenContext';
import AlertContext from '../../../contexts/Alert/AlertContext';
import AuthContext from '../../../contexts/Auth/AuthContext';
import UserContext from '../../../contexts/UserGlobalContext/UserContext';

export default function Information({ location }) {

    
    const [ user, setUser ] = useState({});
    const [ isAllowedToContinue, setIsAllowedToContinue ] = useState(true);

    const { setLoading } = useContext(UserContext);
    const { setAlert } = useContext(AlertContext);
    const { token } = useContext(TokenContext);
    
    const { userInformation } = useContext(AuthContext);
    const { id, numDoc } = userInformation;
    
    useEffect(() => {
        if(Object.keys(user).length > 0){
            if(!!location.state && location.state.method === 'forgot'){
                setLoading(true);
                Api.post('user/resetPassword', { 
                    id: userInformation.id, 
                    numDoc: userInformation.numDoc, 
                    wpId: user.wpId 
                }, { 
                    headers: { 
                        Authorization: `Bearer ${token}` 
                    }}).then(res => {
                        user['claimLink'] = res.data.claimLink
                        user['accessCode'] = res.data.claimLink
                        
                        setUser(user)
                        
                        setIsAllowedToContinue(false);
                        setLoading(false);
                })
            }else{
                setIsAllowedToContinue(false);
            }
        }
    }, [ user, location ])

    useEffect(() => {
        setLoading(true);
        Api.get('/user/information', { headers: { Authorization: `Bearer ${token}` } }).then(res => {
            if(res.status === 200){
                setUser(res.data);
                setAlert({
                    type: 'ok',
                    severity: 'success',
                    message: 'Usuário carregado com sucesso.',
                    show: true
                });
                setLoading(false);
            }
        }).catch(err => {
            setAlert({
                type: 'not ok',
                severity: 'error',
                message: 'Um erro desconhecido ocorreu. Por favor, tente novamente mais tarde.',
                show: true
            });
            setLoading(false);
        });
    }, [ id, numDoc, setAlert, setLoading, token ])

    const define_password = () => {
        if (!user.accessCode || user.accessCode === ''){
            window.location.href = user.claimLink;
        }
        else {
            window.location.href = `https://work.facebook.com/accesscode/?access_code=${user.accessCode}`;    
        }
    }

    return (
        <InfoContainer>
            <h2>Informações do usuário</h2>
            <ul>
                <li><strong>Data do cadastro:</strong> { user.registerDate }</li> 
                <li><strong>Nome completo:</strong> { user.formattedName }</li> 
                <li><strong>Matrícula:</strong> { user.externalId }</li> 
                <li><strong>E-mail:</strong> { user.email ? user.email : "Sem email" }</li> 
                <li><strong>Status:</strong> { user.status ? "Sim" : "Não" }</li> 
                <li><strong>Conta reivindicada:</strong> { user.claimed ? "Sim" : "Não" }</li> 
                <li><strong>Conta convidada:</strong> { user.invited ? "Sim" : "Não" }</li> 
                <li><strong>Data do convite:</strong> { user.inviteDate ? "Sim" : "Não" }</li>
            </ul>
            <div className="button_holder">
                <Button disabled={isAllowedToContinue} onClick={define_password}>
                    Definir senha!
                </Button>
            </div>
        </InfoContainer>
    );
}
