import styled from "styled-components";

export default styled.div`
    width: 100%;
    padding: 20px;
    background: ${props => props.theme.background};
    border-top-left-radius: 5px;

    a{
        color: ${props => props.theme.text};
    }

    .left_content, .right_content{
        width: 48%;
    }

    .update, .models{
        padding: 10px;
    }

    //flex
    display: flex;
    justify-content: space-between;
`;