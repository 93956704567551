import React, { useState } from 'react';

//context
import UserContext from './UserContext';

export default function UserGlobalContext({ children }) {

    const [ loader, setLoader ] = useState(false);

    return (
        <UserContext.Provider 
            value={{
                loading: loader, setLoading: setLoader
            }}>
            {children}
        </UserContext.Provider>
    );
}
