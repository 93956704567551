import styled from 'styled-components';

export default styled.div`
    
    width: 100%;
    height: 100%;
    color: black;
    font-weight: bolder;
    
    .recharts-layer path, .recharts-line path{
        stroke: ${props => props.theme.complementary};
    }

    .recharts-line-dots circle, .recharts-active-dot circle{
        stroke: ${props => props.theme.complementary};
        fill: ${props => props.theme.complementary}9d;
    }

`;