import styled from 'styled-components';
import admin_image from '../../static/images/admin.png';

export default styled.div`
    width: 100%;
    height: 100%;
    background: url(${admin_image});
    background-size: cover;

        .login_box{
            width: 23%;
            height: 45%;
            min-width: 400px;
            padding: 30px;
            background: #FFF;
            color: #333;
        }

        .login_box form{
            width: 100%;
        }
        
            .login_box form h2{
                font-size: 2rem;
                margin-bottom: 1rem;                  
            }

            .login_box form input{
                margin: 5px;
                width: 100%;
                border-radius: 3px;
                border: none;
                padding: 5px;
                background: ${props => props.theme.input.background};
            }
            
            .button{
                margin-top: 5px;
                width: 50%;
            }

            .login_box form button{
                width: 100%;
            }

    //flex
    display: flex;
    justify-content: center;
    align-items: center;

        .login_box{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        
            .login_box form h2, .login_box form label{
                align-self: flex-start;
            }

            .login_box form{
                display: flex;
                align-items: center;
                flex-direction: column;
            }

            .login_box form .button{
                display: flex;
                justify-content: center;
                align-items: center;
            }
`;