import styled from 'styled-components';

export default styled.div`

  width: 100%;

  .button{
    width: 100%;
    border: none;
    background: ${props => props.theme.button.background};
    color: ${props => props.theme.text};
    border-radius: 3px;
    cursor: pointer;
    padding: 6px;
    transition: background 200ms ease;
  }

  .button:hover{
    background: ${props => props.theme.button.hover};
  }

  .button:disabled{
    background: rgba(0, 0, 0, .3);
    color: rgba(255, 255, 255, .3);
  }

`;