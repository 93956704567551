import React from 'react';

//components
import Alert from '../../components/Alert';

//styles
import AdminContent from './styles';

export default function CreateUser({ children }) {
    return (
        <>
            <Alert />
            <AdminContent>
                {children}
            </AdminContent>
        </>
    );
}
