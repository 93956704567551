import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../Button';
import { useHistory } from 'react-router-dom';

//styles
import CheckBoxContainer from './styles';

export default function CheckboxBtn({ sendTo }){

    const history = useHistory();

    const [ checked, setChecked ] = useState(false);

    const handle_check = () => {
        setChecked(!checked);
    }

    const to_information_page = () => {
        history.push(sendTo);
    }

    return(
        <CheckBoxContainer>
            <FormControlLabel 
                control={<Checkbox checked={checked} color='default' onChange={handle_check}/>}
                label={"Aceito os termos."}
                color='inherit'
            />
            <div className={'btn_holder'}>
                <Button disabled={!checked} onClick={to_information_page}>
                    Avançar!
                </Button>
            </div>
        </CheckBoxContainer>
    );
}  