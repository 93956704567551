import React from 'react';
import MaterialPagination from '@material-ui/lab/Pagination';

//styles
import PaginationContainer from './styles';

export default function Pagination({ pagination_config }) {
    
    const { totalPages, setPagination } = pagination_config;

    const handle_change = (evt, value) => {
        setPagination(value);
    }
    
    return (
        <PaginationContainer>
            <MaterialPagination count={totalPages} size='medium' variant="outlined" shape="rounded" onChange={handle_change} />
        </PaginationContainer>
    );
}
