const Theme = {
    primary: "#FFFFFF",
    secundary: "#111111",
    complementary: "#9C0000",
    text: "#F1F1F1",
    hover: "#000000",
    input: {
        background: "#EDEDED",
        border: "#EBEBEB"
    },  
    placeholder: {
        id: "Exemplo: 000123456",
        num_doc: "Exemplo: 1999"
    },
    label: {
        id: "Digite sua matrícula Renner S/A",
        num_doc: "Digite seu ano de nascimento",
    },
    loader: {
        primary: "#000000",
        secundary: "#EDEDED"
    },
    button: {
        background: "#111111",
        hover: "#000000"
    }
}

export default Theme;

