import React, { useState, useEffect } from 'react';

//resources
import Paper from '../../../components/Paper';

//themes
import GlobalStyle from '../../static/css/user_global.js';

//contexts
import { ThemeProvider } from 'styled-components';
import AlertContextProvider from '../../../contexts/Alert/AlertContextProvider';
import tema from '../../../user/static/css/theme';

export default function Home({ Content, ...rest }){
    
    const [ userTheme, setUserTheme ] = useState(tema);
    
    useEffect(() => {
        if(!!sessionStorage.getItem('@userTheme')){
            let new_data = JSON.parse(sessionStorage.getItem('@userTheme'));
            setUserTheme({ ...userTheme, ...new_data.themes.user });
        }
    }, []);

    return(
        <ThemeProvider theme={ userTheme }>
            <GlobalStyle />
            <AlertContextProvider>
                <Paper>
                    <Content {...rest} />
                </Paper>
            </AlertContextProvider>
        </ThemeProvider>
    )
};