module.exports = {
    response_translate(message, optional_complement){
        const friendly_messages = {
            "Credentials do not match": "Usuário ou senha incorreto. Por favor, tente novamente.",
            "Name, id and numDoc must be a non-empty string": "Nome, ID e numDoc devem obrigatóriamente estar preenchidos!",
            "User already exists in our database": "Usuário já existente.",
            "User created successfully": "Usuário criado com sucesso!",
            "Invalid or expired token": "Token de sessão invalido ou expirado! Por favor, re-faça o login para gerar um novo.",
            "term created successfully": "Termo criado com sucesso!",
            "service temporally unavaliable, please try again later": "Serviço temporáriamente indisponível, por favor tente novamente mais tarde"
        } 

        return friendly_messages[message]
    }
}