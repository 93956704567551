import styled from 'styled-components';

export default styled.nav`
    width: 100%;
    color: ${props => props.theme.complementary};
    padding: 5px 10px;
    font-size: 1.5rem;

    ul{
        width: 100%;
    }

    ul li:nth-last-child(1){
        margin-left: auto;
    }

    //flex

    ul{
        list-style: none;
        display: flex;
        align-items: center;
    }
`;