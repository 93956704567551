import styled from 'styled-components';

export default styled.div`
    width: 55%;
    max-width: 700px;
    height: 80%;
    background: white;
    overflow: auto;

    @media only screen and (max-width: 425px) {
        width: 95%;
    }
`;
