import styled from 'styled-components';

export default styled.div`

    padding: 10px 0;

    form input{
        margin-left: 10px;
        border: 1px solid ${props => props.theme.border};
        padding: 3px 10px;
    }

    //flex
    form{
        display: flex;
        align-items: center;
    }

`;