import React, { useState, useContext, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

//services
import Api from '../../../service/api';

//contexts
import AdminGlobalContext from '../../../contexts/AdminGlobalContext/GlobalContext';
import AlertContext from '../../../contexts/Alert/AlertContext';

//components
import Input from '../../../components/Input';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/Remove';

//styles
import UserContainer from './styles';

export default function EditUser() {
    
    const token = Cookies.get('@user');

    const history = useHistory();

    const { editableUser, setEditableUser } = useContext(AdminGlobalContext);
    const { setAlert } = useContext(AlertContext);

    const formRef = useRef(null);

    const [ newQuestions, setNewQuestions ] = useState([]);

    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    
    useEffect(() => {
        const setFormInitialData = async () => {
            const { id, numDoc } = editableUser;
            Api.get(`/admin/users/${id}`, { headers: { Authorization: `Bearer ${token}` } }).then(async res => {

                await setQuestions(res.data.cpData.auth.questions);
                await setAnswers(res.data.cpData.auth.answers);
                
                await formRef.current.setData({
                    name: res.data.wpData.core.name,
                    id,
                    num_doc: numDoc,
                    email : res.data.wpData.core.userName,
                    division: res.data.wpData.enterprise.division,
                    locality : res.data.wpData.core.addresses[0].formatted,
                    department: res.data.wpData.enterprise.department,
                    phoneNumber: res.data.wpData.core.phoneNumbers[0].value,
                    organization: res.data.wpData.enterprise.organization,
                    title: res.data.wpData.core.title,
                });
                
            }).catch(err => {
                setAlert({
                    type: 'error',
                    severity: 'error',
                    message: `Usuário com ID:  não foi encontrado.`,
                    show: true
                });
            });
        }
        setFormInitialData();
    }, [editableUser, formRef, setAlert, token])
    
    useEffect(() => {
        formRef.current.setData({
            questions: questions,
            answers: answers,
        });
    }, [questions,answers]) //questions and answers
    const handle_submit = async (payload, { reset }) => {
        try{
        
            await Api.put('/admin/users', payload, { headers: { Authorization: `Bearer ${token}` } });
            
            setEditableUser({});

            setAlert({
                type: 'ok',
                severity: 'success',
                message: 'Ação efetuada com sucesso.',
                show: true
            })

            reset();

            setTimeout(() => {
                history.push('/admin/panel/users')
            }, 3000)
            
        }catch(err){
            
            setAlert({
                type: 'error',
                severity: 'error',
                message: "Não foi possível completar esta ação.",
                show: true
            });

        }
    }

    const add_input = () => {
        if(newQuestions.length < 2){
            setNewQuestions([...newQuestions, 1])
        }
    }
    
    const minus_input = () => {
        let newQuestionsList = newQuestions.slice();
        newQuestionsList.shift();
        setNewQuestions(newQuestionsList);
    }

    const handle_clear = () => {
        formRef.current.reset();
        setAlert({
            type: 'ok',
            severity: 'success',
            message: 'Campos restaurados com sucesso.',
            show: true
        })
    }
    
    const make_submit = () => {
        formRef.current.submitForm();
        setQuestions([]);
        setAnswers([]);
        setNewQuestions([]);
    }

    return (
        <UserContainer>
            <Form onSubmit={handle_submit} ref={formRef} >
                <header>
                    <h2>Edição de usuário</h2>
                    <div className='add_holder'>
                        <label htmlFor='clear_button'>Limpar</label>
                        <IconButton id='clear_button' size='small' color='inherit' onClick={handle_clear}>
                            <ClearIcon fontSize='large' />
                        </IconButton>
                        <label htmlFor='submit_button'>Salvar</label>
                        <IconButton id='submit_button' size='small' color='inherit' onClick={make_submit}>
                            <SaveIcon fontSize='large' />
                        </IconButton>
                    </div>
                </header>
                <div className='content_holder'>
                    <div className='first_section'>
                        <h3>Informações gerais</h3>
                        <label>Nome:</label>
                        <Input name='name' required />
                        <label>Matricula:</label>
                        <Input name='id' disabled required />
                        <label>Documento:</label> 
                        <Input name='num_doc' disabled required />
                        <label>Cargo:</label>
                        <Input name='title' required />
                        <label>E-mail:</label>
                        <Input name='email' disabled/>
                        <label>Telefone: </label>
                        <Input name='phoneNumber' />
                        <label>Localidade/Cidade:</label>
                        <Input name='locality' />
                        <label>Departamento:</label>
                        <Input name='department' />
                        <label>Organização:</label>
                        <Input name='organization' />
                        <label>Divisão:</label>
                        <Input name='division' />
                    </div>
                    <div className='second_section'>
                        <h3>Informações de autenticação</h3>
                        <label>
                            <p>Perguntas</p>
                            <div>
                                <IconButton size='small' color='inherit' onClick={minus_input}>
                                    <RemoveIcon />
                                </IconButton>
                                <IconButton size='small' color='inherit' onClick={add_input}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </label>
                        {
                            questions.map((item,index) => (
                                <Input key={`${index}_${item}`} name={`questions.${index}`} />
                            ))
                        }
                        {
                            newQuestions.map((item, index) => (
                                <Input key={`${index}_${item}`} name={`questions.${index + questions.length}`} />
                            ))
                        }
                        <label>
                            <p>Respostas</p>
                            <div>
                                <IconButton size='small' color='inherit' onClick={minus_input}>
                                    <RemoveIcon />
                                </IconButton>
                                <IconButton size='small' color='inherit' onClick={add_input}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </label>
                        {
                            answers.map((item, index) => (
                                <Input key={`${index}_${item}`} name={`answers.${index}`} />
                            ))
                        }
                        {
                            newQuestions.map((item, index) => (
                                <Input key={`${index}_${item}`} name={`answers.${index + answers.length}`} />
                            ))
                        }
                    </div>
                </div>
            </Form>
        </UserContainer>
    );
}
