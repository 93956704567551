import React from 'react';

import SearchButton from '../SearchButton';
import Input from '../Input';
import { Form } from '@unform/web';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import IconButton from '@material-ui/core/IconButton';

import SearchContainer from './styles';

export default function SeachInput({ setQuery, searchInputPlaceholder, forceUpdate }) {
    
    const handle_filter = ({ query }, { reset }) => {
        setQuery(query);
        reset();
    }

    const clearFilter = () => {
        forceUpdate(Math.random());
    }
    
    return (
        <SearchContainer>
            <Form onSubmit={handle_filter}>
                <label htmlFor="clearFilter">
                    Limpar Filtro
                </label>
                <IconButton size='small' color='inherit' id="clearFilter" onClick={clearFilter}>
                    <ClearAllIcon fontSize='large'/>
                </IconButton>
                <Input type='text' name='query' placeholder={searchInputPlaceholder} />
                <SearchButton />
            </Form>
        </SearchContainer>
    );
}
