import styled from 'styled-components';

export default styled.div`
    width: 100%;

    form{
        padding: 10px;
        width: 100%;
        text-align: left;
    }

        form input{
            padding: 10px;
            width: 100%;
            border: 1px solid ${props => props.theme.input.border};
            border-radius: 3px;
            background: ${props => props.theme.input.background};
            margin-bottom: 5px;
        }

        form input:focus{
            outline: none;
        }

        form h2{
            margin-bottom: 10px;
        }

        form label{
            font-size: 12px;
        }
`;