import styled from 'styled-components';

export default styled.aside`
    width: 8rem;
    color: ${props => props.theme.text};
    font-size: 1.5rem;
    padding: 10px 0;
    transition: 100ms ease;
    overflow: none;


    :hover{
        width: 30rem;
    }

    :hover .aside_text{
        display: inline-block;
    }

    ul{
        list-style: none;
        height: 100%;
    }

        ul li{
            white-space: nowrap;
            cursor: pointer;
            width: 100%;
            padding: 5px 20px 5px 15px;
        }

            ul li a{
                width: 100%;
                height: 100%;
                text-decoration: none;
                color: ${props => props.theme.text};
            }

            ul li:nth-last-child(1){
                margin-top: auto;
            }
            
            ul li:hover{
                background: ${props => props.theme.hover};
            }

        ul li .aside_icon{
            font-size: 4rem;
            color: ${props => props.theme.complementary};
            filter: grayscale(95%);
            transition: 200ms ease;
        }

            ul li:hover .aside_icon{
                filter: grayscale(0%);
            }
        
        ul li .aside_text{
            display: none;
            position: relative;
            margin-left: 40px;
        }
        
    //flex

    ul{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    ul li, ul li a{
        display: flex;
        align-items: center;
    }
`;