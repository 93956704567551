import styled from "styled-components";

export default styled.div`
    width: 100%;
    padding: 20px;

    .navigation_header{
        width: 100%;
    }

    .table{
        width: 100%;
    }

    .thead{
        text-align: left;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background: ${props => props.theme.complementary};
        color: ${props => props.theme.table.headerColor};
    }

        .thead .tr{
            width: 100%;
        }

            .thead .tr .th{
                padding: 5px;
                font-size: 1.3rem;
            }

    .tbody{
        width: 100%;
        background: ${props => props.theme.primary};
    }

        .tbody .tr, .tbody .tr form{
            width: 100%;
        }
        
            .tbody .tr:nth-child(even){
                background: ${props => props.theme.table.background};
            }

            .tbody .tr:hover{
                background: ${props => props.theme.table.hover.background};
                color: ${props => props.theme.complementary};
                font-weight: bolder;
            }

            .tbody .tr .td{
                padding: 5px;
                font-size: 1rem;
                word-break: break-all;
            }
            


    //flex

    .navigation_header{
        display: flex;
        align-items: center;
    }

    .thead{
        display: flex;
        align-items: center;
    }

        .thead .tr{
            display: flex;
        }

        .thead .tr .th{
            display: flex;
            align-items: center;
        }
    
    .tbody{
        display: flex;
        flex-direction: column;
    }

        .tbody .tr{
            display: flex;
        }

            .tbody .tr form{
                display: flex;
            }

            .tbody .tr .td{
                display: flex;
                flex-grow: 1;
                align-items: center;
            }

`;