import React from 'react';

//styles
import LogoContainer from './styles';

export default function Alest() {
    return (
        <LogoContainer>  
            <svg xmlns="http://www.w3.org/2000/svg" width="541.866" height="276.137" viewBox="0 0 541.866 276.137">
                <g id="Grupo_1" data-name="Grupo 1" transform="translate(-11.544 -129.655)">
                    <path id="Caminho_1" data-name="Caminho 1" d="M11.544,362.653c.836-6.211,1.563-12.438,4.421-18.168,3.993-8.008,10.427-13.189,18.666-16.3A58.366,58.366,0,0,1,52.5,324.606c12.147-.6,24.295-.3,36.438.228,2.842.124,5.682.279,8.524.4,1.176.049,1.221.022,1.2-1.124-.1-5.274-.127-10.553-.391-15.82a13.757,13.757,0,0,0-6.28-11.016,29.439,29.439,0,0,0-11.355-4.353,77.739,77.739,0,0,0-15.569-1.19,192.752,192.752,0,0,0-25.839,2.147c-5.219.737-10.419,1.574-15.6,2.547a7.347,7.347,0,0,1-.989.06,2.2,2.2,0,0,1-.221-1.452q-.015-7.912,0-15.826c0-1.668-.282-1.514,1.43-1.843a203.989,203.989,0,0,1,25.524-3.343,193.335,193.335,0,0,1,27.358.121c7.611.593,15.238,1.187,22.593,3.5a53.989,53.989,0,0,1,11.257,4.752c8.056,4.737,12.176,11.842,12.7,21.107.054.946.075,1.9.071,2.844q-.165,48.1-.345,96.2c-.009,2.513.328,2.176-2.138,2.191-3.082.02-6.166-.045-9.246.031a2.23,2.23,0,0,1-2.371-1.341c-1.694-3.189-3.527-6.3-5.315-9.443-.74-1.3-.755-1.3-2.159-.656A154.852,154.852,0,0,1,76.3,402.623a96.887,96.887,0,0,1-18.015,3.019,50.392,50.392,0,0,1-22.837-3.29c-10.173-4.172-16.66-11.813-20.32-22.019A57.291,57.291,0,0,1,12.2,367.19c-.141-1.284-.433-2.551-.655-3.825Zm87.629-2.674c0-3.972.016-7.944-.006-11.916-.012-1.985.325-1.582-1.789-2.051-.172-.038-.353-.038-.529-.064-5.983-.862-11.98-1.6-18.019-1.945-7.65-.442-15.286-.5-22.863.88a34.371,34.371,0,0,0-11.979,4.245,17.3,17.3,0,0,0-8.121,10.784,28.582,28.582,0,0,0-.581,6.89,14.883,14.883,0,0,0,3.919,10.211,21.9,21.9,0,0,0,7.1,5.242,36.709,36.709,0,0,0,15.482,3.476c13.444.242,25.457-4.117,36.494-11.545a1.994,1.994,0,0,0,.9-1.934C99.158,368.161,99.173,364.07,99.173,359.979Z"/>
                    <path id="Caminho_2" data-name="Caminho 2" d="M287.456,345.8l-43.566-.332c-.356,0-.713.017-1.067-.01-.68-.052-.949.25-.951.925-.025,8.031.9,15.859,5.013,22.978,3.908,6.762,9.9,10.849,17.2,13.209a35.286,35.286,0,0,0,10.293,1.734c9.428.167,18.844-.039,28.26-.545q12.606-.678,25.181-1.749c.75-.063,1.084.058,1.081.885q-.031,8.358.006,16.716c.006.909-.441,1.033-1.179,1.141-6.5.958-13.019,1.822-19.556,2.526-7.842.845-15.7,1.43-23.586,1.507-6.4.063-12.846.121-19.122-1.256-23.147-5.079-38.445-18.725-44.892-41.775a78.022,78.022,0,0,1-2.306-15.262,170.591,170.591,0,0,1,1.072-33.18c1.138-9.441,5.451-17.444,12.1-24.184,7.245-7.34,16.049-12.048,25.83-15.025a73.978,73.978,0,0,1,30.048-2.809c9.731,1.09,18.876,3.968,26.982,9.621a42.91,42.91,0,0,1,18.479,29.965,125.051,125.051,0,0,1,.888,23.949c-.142,3.375-.37,6.746-.58,10.117-.078,1.25-.105,1.283-1.355,1.273-4.268-.036-8.535-.136-12.8-.145q-15.736-.033-31.474-.007ZM277.1,326.82q16.542,0,33.082,0c1.567,0,1.566-.014,1.658-1.5a32.169,32.169,0,0,0-5.378-20.237c-7.858-11.889-22.239-17.658-36.653-14.665-15.884,3.3-28.036,17.567-27.087,34.522.117,2.094-.064,1.877,2.009,1.877Q260.917,326.824,277.1,326.82Z"/>
                    <path id="Caminho_3" data-name="Caminho 3" d="M460.323,297.575c-2.093-.4-4.176-.776-6.253-1.184a263.525,263.525,0,0,0-31.472-4.42,95,95,0,0,0-22.872.611,33.529,33.529,0,0,0-10.283,3.241c-4.183,2.218-7.2,5.428-8.1,10.206a19.442,19.442,0,0,0,1.291,11.829,13.743,13.743,0,0,0,6.8,6.749,32.382,32.382,0,0,0,10.947,2.822c5.842.632,11.713.664,17.58.731a140.965,140.965,0,0,1,17.378,1.058,50.4,50.4,0,0,1,11.52,2.851,28.127,28.127,0,0,1,17.236,17.74,49.307,49.307,0,0,1,2.152,24.867A30.125,30.125,0,0,1,453.3,394.729a52,52,0,0,1-18.362,7.768,100.816,100.816,0,0,1-21.133,2.513,180.037,180.037,0,0,1-48.031-5.621c-1.655-.418-1.656-.434-1.657-2.062q0-8.891,0-17.783V378.2c.488-.371.865-.122,1.247-.025,9.131,2.3,18.32,4.319,27.637,5.7a121.133,121.133,0,0,0,23.38,1.514,44.973,44.973,0,0,0,14.594-2.756,25.036,25.036,0,0,0,3.968-1.975c7.782-4.791,9.562-13.671,7.284-20.643a11.456,11.456,0,0,0-6.223-6.949,25.652,25.652,0,0,0-8.216-2.115c-5.124-.606-10.277-.831-15.427-1.045-6.04-.252-12.08-.458-18.094-1.117a44.793,44.793,0,0,1-18.148-5.727,32.341,32.341,0,0,1-14.853-18.585,42.931,42.931,0,0,1-.61-24.023,31.966,31.966,0,0,1,14.585-19.61,55.394,55.394,0,0,1,18.458-7.1c10.515-2.211,21.153-2.508,31.843-1.945A213.376,213.376,0,0,1,460,276.54c1.54.337,1.563.339,1.488,1.778q-.488,9.321-1,18.641A3.677,3.677,0,0,1,460.323,297.575Z"/>
                    <path id="Caminho_4" data-name="Caminho 4" d="M186.233,267.321V402.48c0,2.172,0,2.172-2.142,2.172q-11.026,0-22.053,0c-1.644,0-1.549.145-1.55-1.516V132.1c0-2.45,0-2.45,2.409-2.45h21.341c.415,0,.83.014,1.244,0,.511-.016.764.2.75.727s0,1.067,0,1.6Z"/>
                    <path id="Caminho_5" data-name="Caminho 5" d="M497.019,315.874q0-31.657,0-63.314c0-2.294-.209-1.955,2.005-1.943,3.5.018,6.995.071,10.492.122,1.56.023,1.567.035,2.113,1.53q3.264,8.922,6.52,17.846c.574,1.571.577,1.576,2.234,1.577q14.672,0,29.344,0c.356,0,.712-.007,1.067,0,1.337.032,1.362.034,1.365,1.4q.015,9.159,0,18.318c0,1.435-.023,1.454-1.479,1.455q-13.782.009-27.566,0c-2.039,0-1.778-.093-1.787,1.841q-.179,37.347-.357,74.7a18.459,18.459,0,0,0,1.514,7.788c2.172,4.807,5.884,7.5,11.212,7.657,5.866.173,11.736.206,17.6.318,2.448.047,2.086-.151,2.093,2.1.018,5.631,0,11.263.008,16.895,0,1.673-.029,1.528-1.578,1.528-9.011,0-18.022.055-27.032-.027a34.361,34.361,0,0,1-13.346-2.59c-6.8-2.911-10.877-8.16-12.892-15.148a43.44,43.44,0,0,1-1.54-12.123Q497.027,345.841,497.019,315.874Z"/>
                    <path id="Caminho_6" data-name="Caminho 6" d="M207.957,129.874l60.471,44.657a2.348,2.348,0,0,1,0,.258.517.517,0,0,1-.049.168.4.4,0,0,1-.109.138q-29.7,22.165-59.4,44.325c-.228.171-.474.318-.731.489C207.756,218.461,207.581,135.058,207.957,129.874Z"/>
                    <path id="Caminho_7" data-name="Caminho 7" d="M55.849,219.915h1.568c9.6,0,19.2.017,28.806-.021a4.507,4.507,0,0,1,3.045,1.094q17.061,13.675,34.184,27.273c.644.514,1.286,1.03,1.889,1.513-.072.562-.439.392-.68.393-11.2.008-22.405-.013-33.607.038a3.5,3.5,0,0,1-2.47-1.142Q73.671,236,58.757,222.939c-1.015-.888-2.022-1.785-3.033-2.677Z"/>
                    <path id="Caminho_8" data-name="Caminho 8" d="M222.583,250.2a2.505,2.505,0,0,1,1.054-1.139Q241.22,235.031,258.8,221a4.294,4.294,0,0,1,2.881-1.04c9.96.022,19.921-.019,29.881-.032a3.965,3.965,0,0,1,1.583.108c.063.541-.427.657-.714.878q-18.522,14.271-37.056,28.525a3.7,3.7,0,0,1-2.409.878c-9.842-.054-19.684-.058-29.526-.077C223.209,250.239,222.976,250.219,222.583,250.2Z"/>
                </g>
            </svg>
        </LogoContainer>
    );
}
