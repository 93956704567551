import styled from 'styled-components';

export default styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    min-height: 100px;
    background: ${props => props.theme.primary};
    border-bottom-left: 1px solid ${props => props.theme.border};
    border-bottom-right: 1px solid ${props => props.theme.border};
    border-left: 1px solid ${props => props.theme.border};
    border-right: 1px solid ${props => props.theme.border};
    border-top: 5px solid ${props => props.theme.complementary};
    border-radius: 3px;
`;