import styled from 'styled-components';

export default styled.div`
    width: 100%;
    height: calc(100vh - 56px);
    border-radius: 5px;
    padding: 10px;
    background: ${props => props.theme.background};
    overflow: auto;
    
    .mini_box_holder{
        width: 100%;
        height: 15rem;
        margin-bottom: 2rem;
    }
    
    .big_box_holder{
        width: 100%;
        height: 50rem;
        margin-bottom: 10px;
    }

    .section_title{
        margin-bottom: 1rem;
    }

    .card_title{
        width: 100%;
        padding: 10px;
        font-size: 1.2rem;
    }

    .card_content{
        text-align: center;
        width: 100%;
        height: calc(100% - 35px);
        font-size: 8rem;
    }

    .card_content:hover p{
        transition: 200ms ease;
    }

    .card_content:hover p{
        transform: translateY(-10%);
    }

    //flex

    display: flex;
    flex-direction: column;

    .boxes{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-around;
    }

    .big_box_holder{
        display: flex;
        flex-direction: column;
    }

    .card_content{
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
