import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

import SearchContainer from './styles';

function SearchButton() {
    return(
        <SearchContainer>
            <button><SearchIcon /></button>
        </SearchContainer>
    )
}  
  
export default SearchButton;