import React, { useState } from 'react';

import UserChangeContext from './GlobalContext';

export default function UserChange({ children }) {
    
    const [editableUser, setEditableUser] = useState({});
    const [theme, setTheme] = useState(JSON.parse(localStorage.getItem('theme')) || false);

    const handle_switch = () => {
        localStorage.setItem('theme', !theme)
        setTheme(!theme)
    } 
    
    return (
        <UserChangeContext.Provider 
            value={{ 
                editableUser, setEditableUser,
                theme, setTheme: handle_switch
            }}>
            {children}
        </UserChangeContext.Provider>
    );
}
