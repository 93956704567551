import styled from 'styled-components';

export default styled.div`
    .file_input{
        display: none;
    }

    .icon{
        cursor: pointer;
    }

    label{
        display: flex;
        align-items: center;
    }

    svg{
        margin-right: 3px;
    }

`;