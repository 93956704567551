import styled from 'styled-components';

export default styled.div`

    position: relative;

    ul{
        padding: 8px 0px;
        background: ${props => props.theme.primary};
        border: 1px solid ${props => props.theme.border};
        font-size: 1.5rem;
        border-radius: 5px;
        position: absolute;
        top: 5rem;
        right: 1.5rem;
        min-width: 15rem;
        z-index: 9999;
    }

        .dropdown_link{
            width: 100%;
            padding: 5px;
            cursor: pointer;
            filter: grayscale(100%);
            transition: 200ms ease;
        }

            .dropdown_link:hover{
                background: ${props => props.theme.hover};
                filter: grayscale(0%);
            }
            
            .dropdown_link:nth-child(1){
                text-align: center;
            }
            
            .dropdown_link:hover span:nth-child(2){
                opacity: 1;
            }

            .dropdown_link span:nth-child(1){
                font-size: 2rem;
                margin-right: .5rem;
            }
            .dropdown_link span:nth-child(2){
                position: relative;
                top: -3px;
                color: ${props => props.theme.text};
                opacity: .5;
            }

    //flex
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    ul{
        display: flex;
        flex-direction: column;
    }
`;