import React, { useRef, useState, useContext } from 'react';
import { Form } from '@unform/web';
import ColorPicker from '../../../components/ColorPicker';
import IconButton from '@material-ui/core/IconButton';
import UpArrow from '@material-ui/icons/ArrowUpward';
import Cookies from 'js-cookie';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';

//services
import Api from '../../../service/api';

//components
import Input from '../../../components/Input';

//styles
import SettingsContainer from './styles';
import AlertContext from '../../../contexts/Alert/AlertContext';

export default function Settings() {

    const token = Cookies.get('@user');

    const refs = new Map([
        [ 'adminTheme', useRef(null) ], 
        [ 'userTheme', useRef(null) ],
        [ 'logo', useRef(null) ],
        [ 'questions', useRef(null) ],
        [ 'consent', useRef(null) ]
    ]);

    const [ progressList, setProgressList ] = useState({});
    const [ successList, setSuccessList ] = useState({});

    const { setAlert } = useContext(AlertContext);

    const handle_submit = async (local, payload) => {
        
        switch(local){
            case 'consent':
                
                var form_data = new FormData();

                form_data.append('term', payload.term);
                try{

                    await Api.post(
                        '/admin/term', 
                        form_data, 
                        { 
                            headers: { 
                                Authorization: `Bearer ${token}`, 
                                'Content-Type': 'multipart/form-data' 
                            },
                            onUploadProgress: e => handle_progress('consent', parseInt(Math.round((e.loaded * 100) / e.total )))
                        });

                    setSuccessList({ ...successList, consent: true });

                }catch{
                    setSuccessList({ ...successList, consent: false });
                }
                
                break;

            case 'theme':


                try{
                    await Api.post('/admin/setup', payload, { headers: { Authorization: `Bearer ${token}` } });
                    setSuccessList({ ...successList, questions: true });
                }catch{
                    setSuccessList({ ...successList, questions: false });
                }

                break;

            case 'questions':

                try{
                    await Api.post('/admin/questions', payload.authentication, { headers: { Authorization: `Bearer ${token}` } });
                    setSuccessList({ ...successList, questions: true });
                }catch{
                    setSuccessList({ ...successList, questions: false });
                }

                break;

            case 'logo':

                // eslint-disable-next-line no-redeclare
                var form_data = new FormData();
                
                form_data.append('logo', payload.logo);

                let file = URL.createObjectURL(payload.logo)
                let img = new Image()

                img.onload = () => {
                    if(img.width > 318 || img.height > 100){
                        setAlert({
                            type: 'error',
                            severity: 'error',
                            message: 'A imagem inserida tem dimensões excessivas. Dimensão máxima: 318 x 100',
                            show: true
                        })
                    }else{
                        Api.post('/admin/logo', form_data, { 
                            headers: { 
                                Authorization: `Bearer ${token}`, 
                                'Content-Type': "multipart/form-data; boundary=---011000010111000001101001" 
                            },
                            onUploadProgress: e => handle_progress('logo', parseInt(Math.round((e.loaded * 100) / e.total ))) 
                        }).then(res => {
                            setSuccessList({ ...successList, logo: true });
                        }).catch(err => {
                            setSuccessList({ ...successList, logo: false });
                        })
                    }
                }

                img.src = file
                break;

            default:
                break;
        }
    }

    const handle_progress = async (target, progress) => {
        
        let new_data = {};
        new_data[target] = { progress };

        setProgressList({ ...progress, ...new_data });

    }

    return (
        <SettingsContainer>
            <div className="left_pane">
                <ul>
                    <li><a href='#general'>Geral</a></li>
                    {/* <li><a href='#authentication'>Autenticação</a></li> */}
                    <li><a href='#security'>Privacidade e Segurança</a></li>
                    {/* <li><a href='#themes'>Temas</a></li> /*}
                    {/* <li><a href='#alerts'>Alertas</a></li> */}
                </ul>
            </div>
            <div className="right_pane">
                <div id="general">
                    <div className='header'>
                        <h1>Geral</h1>
                        <h4>Informações básicas da plataforma e gerenciamento de conta.</h4>
                    </div>
                    <div className="section">
                        <div className='section_title'>
                            <h2>Informações de Organização</h2>
                            <div className='section_title_options'>
                                {
                                    !!progressList.logo && progressList.logo.progress === 100 && !!successList.logo
                                    ? <CheckCircleIcon color='inherit' />
                                    : successList.logo === false 
                                    ? <ErrorIcon color='inherit' />
                                    : null
                                }
                                {
                                    successList.logo === undefined
                                    ? <CircularProgress variant='determinate' color='inherit' size={20} value={!!progressList.logo ? progressList.logo.progress : 0} />
                                    : null
                                }
                                <IconButton size='small' color='inherit' onClick={() => refs.get('logo').current.submitForm()}><UpArrow fontSize='large'/></IconButton>
                            </div>
                        </div>
                        <Form onSubmit={payload => handle_submit('logo', payload)} ref={refs.get('logo')}>
                            <div>
                                <p>Alterar logo da empresa</p>
                                <Input type='file' accept='image/png' name='logo' />
                            </div>
                        </Form>
                    </div>
                    {/* <div className="section">
                        <Form onSubmit={payload => handle_submit('general', payload)}>
                            <h2>Informações de Usuário</h2>
                            <div>
                                <p>Alterar nome de usuário</p>
                                <Input type='text' name='user.new_username' placeholder="Novo nome de usuário" />
                            </div>
                            <div>
                                <p>Alterar foto de perfil</p>
                                <input type='file' />
                            </div>
                            <div>
                                <input type='submit' value='Enviar'/>
                            </div>
                        </Form>
                    </div> */}
                </div>
                {/* <div id="authentication">
                    <div className='header'>
                        <h1>Autenticação</h1>
                        <h4>Gerenciamento de perguntas de autenticação de usuário.</h4>
                    </div>
                    <div className='section'>
                         <Form onSubmit={handle_submit}>
                            <h2>Login</h2>
                            <div>
                                <p>Campo 1</p>
                                <Input type='text' name='authentication.login[0]' placeholder="Ex: Qual sua matrícula?" />
                            </div>
                            <div>
                                <p>Campo 2</p>
                                <Input type='text' name='authentication.login[1]' placeholder="Ex: Ultimos 4 digitos do seu RG" />
                            </div>
                        </Form> }
                        <div className='section_title'>
                            <h2>Questões padrão</h2>
                            <div className='section_title_options'>
                                {
                                    !!successList.questions
                                    ? <CheckCircleIcon color='inherit' />
                                    : successList.questions === false 
                                    ? <ErrorIcon color='inherit' />
                                    : null
                                }
                                <IconButton size='small' color='inherit' onClick={() => refs.get('questions').current.submitForm()}><UpArrow fontSize='large'/></IconButton>
                            </div>
                        </div>
                        <Form onSubmit={payload => handle_submit('questions', payload)} ref={refs.get('questions')}>
                            <div>
                                <p>Questão 1</p>
                                <Input type='text' name='authentication.questions[0]' placeholder="Ex: Qual seu nome?" />
                            </div>
                            <div>
                                <p>Questão 2</p>
                                <Input type='text' name='authentication.questions[1]' placeholder="Ex: Qual sua data de nascimento?" />
                            </div>
                            <div>
                                <p>Questão 3</p>
                                <Input type='text' name='authentication.questions[2]' placeholder="Ex: Qual seu sobrenome?" />
                            </div>
                        </Form>
                    </div>
                </div> */}
                <div id="security">
                    <div className='header'>
                        <h1>Privacidade e Segurança</h1>
                        <h4>Gerenciamento de políticas de privacidade e segurança da empresa.</h4>
                    </div>
                    {/* <div className="section">
                        <Form onSubmit={payload => handle_submit('security', payload)}>
                            <h2>Segurança da conta</h2>
                            <div>
                                <p>Alterar senha</p>
                                <Input type='password' name='security.old_password' placeholder="Senha antiga." />
                                <Input type='password' name='security.new_password' placeholder="Nova Senha." />
                            </div>
                            <div>
                                <p>Alterar E-mail</p>
                                <Input type='email' name='security.new_email' placeholder="Senha antiga." />
                            </div>
                            <div>
                                <input type='submit' value='Enviar'/>
                            </div>
                        </Form>
                    </div> */}
                    <div className="section">
                        <div className='section_title'>
                            <h2>Consentimento</h2>
                            <div className='section_title_options'>
                                {
                                    !!progressList.consent && progressList.consent.progress === 100 && !!successList.consent
                                    ? <CheckCircleIcon color='inherit' />
                                    : successList.consent === false 
                                    ? <ErrorIcon color='inherit' />
                                    : null
                                }
                                {
                                    successList.consent === undefined
                                    ? <CircularProgress variant='determinate' color='inherit' size={20} value={!!progressList.consent ? progressList.consent.progress : 0} />
                                    : null
                                }
                                <IconButton size='small' color='inherit' onClick={() => refs.get('consent').current.submitForm()}><UpArrow fontSize='large'/></IconButton>
                            </div>
                        </div>
                        <Form onSubmit={payload => handle_submit('consent', payload)} ref={refs.get('consent')}>
                            <div>
                                <p>Alterar termo de uso</p>
                                <Input type='file' accept={'.pdf'} name='term' />
                            </div>
                        </Form>
                    </div>
                </div>
                {/* <div id="themes">
                    <div className='header'>
                        <h1>Temas</h1>
                        <h4>Personalização de temas e cores da plataforma.</h4>
                    </div>
                     <div className='section'>
                        <div className='section_title'>
                            <h2>Customização do painel de administrador.</h2>
                            <IconButton size='small' color='inherit' onClick={() => refs.get('adminTheme').current.submitForm()}><UpArrow fontSize='large'/></IconButton>
                        </div>
                        <div className='section_content_holder'>
                            
                            <Form onSubmit={payload => handle_submit('theme', payload)} ref={refs.get('adminTheme')}>
                                <div className='theme_block'>
                                    <p>Cor complementar</p>
                                    <div className='color_picker_holder'>
                                        <div className='pickers_title'>
                                            <label>Tema claro</label><label>Tema escuro</label>
                                        </div>
                                        <div className='pickers'>
                                            <ColorPicker name='adminTheme.light.complementary' />
                                            <ColorPicker name='adminTheme.dark.complementary' />
                                        </div>
                                    </div>
                                </div>
                                <div className='theme_block'>
                                    <p>Cor de fundo do botão</p>
                                    <div className='color_picker_holder'>
                                        <div className='pickers'>
                                            <ColorPicker name='adminTheme.light.button.background' />
                                            <ColorPicker name='adminTheme.dark.button.background' />
                                        </div>
                                    </div>
                                </div>
                                <div className='theme_block'>
                                    <p>Hover do botão</p>
                                    <div className='color_picker_holder'>
                                        <div className='pickers'>
                                            <ColorPicker name='adminTheme.light.button.hover' />
                                            <ColorPicker name='adminTheme.dark.button.hover' />
                                        </div>
                                    </div>
                                </div>    
                            </Form>

                        </div>
                        
                    </div>
                    <div className='section'>
                        <div className='section_title'>
                            <h2>Customização do fluxo de usuário.</h2>
                            <IconButton size='small' color='inherit' onClick={() => refs.get('userTheme').current.submitForm()}><UpArrow fontSize='large'/></IconButton>
                        </div>
                        <div className='section_content_holder'>

                            <Form onSubmit={payload => handle_submit('theme', payload)} ref={refs.get('userTheme')}>
                                <div className='theme_block'>
                                    <p>Cor primária do loader</p>
                                    <ColorPicker name='userTheme.loader.primary' />
                                </div>
                                <div className='theme_block'>
                                    <p>Cor secundária do loader</p>
                                    <ColorPicker name='userTheme.loader.secundary' />
                                </div>
                                <div className='theme_block'>
                                    <p>Cor de fundo do botão</p>
                                    <ColorPicker name='userTheme.button.background' />
                                </div>
                                <div className='theme_block'>
                                    <p>Hover do botão</p>
                                    <ColorPicker name='userTheme.button.hover' />
                                </div>
                            </Form>

                        </div>
                    </div>
                </div>
                {/* <div id="alerts">
                    <div className='header'>
                        <h1>Alertas</h1>
                        <h4>Personalização de alertas para o usuário.</h4>
                    </div>
                    <div className='section'>
                        <Form onSubmit={handle_submit}>
                            <h2>Erro</h2>
                            <div>
                                <p>Usuário não encontrado.</p>
                                <Input type='text' name='error.not_found' placeholder="O usuário não foi encontrado." />
                            </div>
                            <div>
                                <p>Usuário/Senha incorretos.</p>
                                <Input type='text' name='error.credential_fail' placeholder="O usu." />
                            </div>
                        </Form>
                    </div>
                </div> */} 
            </div>
        </SettingsContainer>
    );
}
