import styled from 'styled-components';

export default styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 5px;
    background: ${props => props.theme.background};
    padding: 20px;
    scroll-behavior: smooth;

    .left_pane{
        width: 25%;
        font-size: 1.5rem;
        background: ${props => props.theme.primary};
        position: fixed;
        height: 185px;
        border-radius: 5px;
        border: 1px solid ${props => props.theme.border};
    }

        .left_pane ul{
            list-style: none;
            width: 100%;
            padding: 10px 0;
        }
        
            .left_pane ul li{
                width: 100%;
                padding: 10px;
                margin-bottom: .5rem;
                background: ${props => props.theme.hover};
                transition: 200ms ease;
            }
        
            .left_pane ul li a{
                color: ${props => props.theme.text};
                width: 100%;
                display: block;
            }
            
            .left_pane ul li:hover{
                cursor: pointer;
                background: transparent;
            }
        
        .right_pane{
            width: 75%;
            margin-left: 35%;
        }

            #general, #authentication, #security, #themes, #alerts{
                background: ${props => props.theme.primary};
                border: 1px solid ${props => props.theme.border};
                margin-bottom: 1rem;
                border-radius: 5px;
                border-top: 5px solid ${props => props.theme.complementary};
            }

            .right_pane .header{
                padding: 15px;
            }

            .right_pane .section{
                width: 100%;
                border-top: 1px solid ${props => props.theme.border};
                border-bottom: 1px solid ${props => props.theme.border};
                padding: 15px;
            }

                .right_pane .section form h2{
                    margin-bottom: 1rem;
                }

                .right_pane .section form div{
                    margin-bottom: .5rem;
                }

                .right_pane .section form div p, .right_pane .section form div input{
                    width: 50%;
                }

                .right_pane .section form div input[type=submit]{
                    width: 30%;
                }

                .right_pane .section form div input[type=text]{
                    border: none;
                    border-radius: 3px;
                    background: ${props => props.theme.background};
                    padding: 5px;
                    color: ${props => props.theme.text};
                    border: 1px solid ${props => props.theme.hover};
                }
                
                .right_pane .section form div input[type=password]{
                    width: 25%;
                    margin-right: 3px;
                    border: none;
                    border-radius: 3px;
                    background: ${props => props.theme.background};
                    padding: 5px;
                    color: ${props => props.theme.text};
                    border: 1px solid ${props => props.theme.hover};
                }

                .section_title{
                    margin-bottom: 3rem;
                }

                .pickers_title{
                    width: 100%;
                }

                .pickers .twitter-picker{
                    margin-right: 5px;
                }

                .section_title_options{
                    width: 55px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                
    //flex
    display: flex;
    
    .right_pane .section form div{
        display: flex;
        align-items: center;
    }

    .twitter-picker div:nth-child(3){
        display: flex;
        flex-wrap: wrap;
    }
    .twitter-picker div:nth-child(2){
        visibility: hidden;
    }

    .section_title{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .color_picker_holder{
        display: flex;
        flex-direction: column;
    }

    .pickers_title{
        display: flex;
        justify-content: space-between;
    }


`;