import React, { useState } from 'react';
import AuthContext from './AuthContext';

export default function AuthProvider({ children }) {
    
    const [ userAuth, setUserAuth ] = useState(false);
    const [ adminAuth, setAdminAuth ] = useState(false);
    const [ userInformation, setUserInformation ] = useState({});

    return (
        <AuthContext.Provider value={{ 
            userAuth, setUserAuth, 
            userInformation, setUserInformation,
            adminAuth, setAdminAuth,
        }}>
            {children}
        </AuthContext.Provider>
    );
}