import styled from 'styled-components';

export default styled.div`
    
    width: 100%;
    margin-bottom: 1rem;
    
    .MuiPagination-ul li button{
        border: 1px solid ${props => props.theme.border};
        color: ${props => props.theme.text};
    }
`;