import styled from "styled-components";

export default styled.div`
    color: #F1F1F1;
    width: 50%;

    label{
        font-size: 1.2rem;
        cursor: pointer;
    }

    .tutorial_section_title{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .5rem;
    }

    .tutorial_section_title div:nth-child(2) button{
        margin: 0px 3px;
    }
`;