import React, { useEffect, useState } from 'react';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import IconButton from '@material-ui/core/IconButton';

//styles
import tema from '../../static/css/theme';
import GuideContainer from './styles';
import GlobalStyle from '../../static/css/user_global';
import { ThemeProvider } from 'styled-components';

import { useHistory } from 'react-router-dom';

function Guide({ Content }) {

    const [ videoList, setVideoList ] = useState([]);
    const [ video, setVideo ] = useState(undefined);
    const [ userTheme, setUserTheme ] = useState(tema);

    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            setVideoList([
                "https://storage.cloud.google.com/portal-claim-enterprise-logo/Clannad_After_Story_19_PA-Anbient.mkv?authuser=2&hl=pt-BR&organizationId=213575408031",
                "https://storage.cloud.google.com/portal-claim-enterprise-logo/Clannad_After_Story_22_PA-Anbient.mkv?authuser=2&hl=pt-BR&organizationId=213575408031"
            ])
        }, 4500);
    }, [])

    
    useEffect(() => {
        if(!!sessionStorage.getItem('@userTheme')){
            let new_data = JSON.parse(sessionStorage.getItem('@userTheme'));
            setUserTheme({ ...userTheme, ...new_data.themes });
        }
    }, [])

    const handle_back = () => {
        history.goBack();
    }

    return(
        <GuideContainer>
            <ThemeProvider theme={ userTheme }>
                <GlobalStyle />
                <div className='tutorial_section_title'>
                    <div>
                        <h1>Como utilizar o Claim Portal</h1>
                    </div>
                    <div>
                        <button onClick={() => setVideo(videoList[0])}>Como se cadastrar</button>
                        <button onClick={() => setVideo(videoList[1])}>Recuperar senha</button>
                    </div>
                </div>
                <Content uri={video} />
                <label htmlFor='voltar'>Voltar</label><IconButton color='inherit' size='small' id='voltar' onClick={handle_back}><KeyboardReturnIcon fontSize='large'/></IconButton>
            </ThemeProvider>
        </GuideContainer>
    );
}

export default Guide;