import React from 'react';

//styles
import LogoContainer from './styles.js';

//resources
import alest from '../../user/static/images/logos/alestlogo.svg';
import workplace from '../../user/static/images/logos/workplace.png';

//contexts

export default function Logos() {

  const cliente = !!sessionStorage.getItem('@userTheme') ? JSON.parse(sessionStorage.getItem('@userTheme')) : undefined;

  return (
    <LogoContainer>
      <div>
          <img src={alest} alt='alest logo' />
      </div>
      {
        !!cliente
        ? <div><img src={cliente.logo} alt='cliente logo' /></div>
        : null
      }
      <div>
          <img src={workplace} alt='workplace logo' />
      </div>
    </LogoContainer>
  );
}
