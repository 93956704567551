import styled from 'styled-components';

export default styled.div`
    width: 100%;
    padding: 1rem;
  
    h1, h2{
        text-align: center;
    }

    h1{
        font-size: 3em;
    }

    h2{
        font-size: 2em;
    }

    p{
        text-align: justify;
        font-size: 1.5em;
    }
`;