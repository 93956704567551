import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Form } from '@unform/web';
import { response_translate } from '../../../Tools/responseHandler';

//styles
import LoginFormContainer from './styles';

//services
import Api from '../../../service/api';

//components
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';

//contexts
import AuthContext from '../../../contexts/Auth/AuthContext';
import AlertContext from '../../../contexts/Alert/AlertContext';

export default function Login() {

    const { setAdminAuth } = useContext(AuthContext);
    const { setAlert } = useContext(AlertContext);

    const history = useHistory();

    useEffect(() => {
        if(localStorage.getItem('loggedIn') !== null){
            setAdminAuth(true);
            history.push('/admin/panel')
        }  
    }, [ history, setAdminAuth ])

    const handle_submit = async ({ username, password }) => {
        try{
            const res = await Api.post('/admin/auth', { username, password });
            
            if(res.status === 200){
                setAdminAuth(true);
                localStorage.setItem('loggedIn', JSON.stringify(res.data.providerData[1] || res.data.providerData[0]));
                Cookies.set('@user', res.data.stsTokenManager.accessToken, { expires: new Date(res.data.stsTokenManager.expirationTime) })
            }
        }catch(err){
            setAlert({
                type: 'error',
                severity: 'error',
                message: response_translate(err.response.data.msg),
                show: true
            })
        }         
    }

    return (
        <>
            <Alert />
            <LoginFormContainer>
                <Form onSubmit={handle_submit}>
                    <h2>Login</h2>
                    <label>E-mail</label>
                    <Input name="username" placeholder="usuario@dominio.com.br" type="email" autoComplete="username" required />
                    <label>Senha</label>
                    <Input name="password" type="password" autoComplete="current-password" required />
                    <div className="button">
                        <Button>Entrar</Button>
                    </div>
                </Form>   
            </LoginFormContainer>
        </>
    );
}
