import styled from 'styled-components';

export default styled.div`
    width: 100%;

    h2{
        margin-bottom: 15px;
    }

    ul{
        list-style: none;
        margin-bottom: 15px;
    }
`;