import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import Input from '../../../components/Input';
import Api from '../../../service/api';

//resources
import Button from '../../../components/Button';

//styles
import QuestionsContainer from './styles';

//contexts
import TokenContext from '../../../contexts/Token/TokenContext';
import UserContext from '../../../contexts/UserGlobalContext/UserContext';
import AlertContext from '../../../contexts/Alert/AlertContext';
import AuthContext from '../../../contexts/Auth/AuthContext';

export default function Questions({ computedMatch, location }) {

    const { action } = computedMatch.params;
    const history = useHistory();

    const [ questions, setQuestions ] = useState([]);
    const [ answers, setAnswers ] = useState([]);

    const { setToken } = useContext(TokenContext);
    const { setAlert } = useContext(AlertContext);
    const { loading, setLoading } = useContext(UserContext);
    const { userInformation } = useContext(AuthContext);

    useEffect(() => {
        setLoading(true);
        Api.post('/user/questions', { id: userInformation.id, numDoc: userInformation.numDoc }).then(res => {
            setQuestions(res.data.questions);
            setLoading(false);
        });
    }, [setLoading, userInformation.id, userInformation.numDoc])

    useEffect(() => {
        let isAnswerEmpty = false;
        
        if(answers.length > 0){
            let array_user_answers = questions.filter(question => question.trim() !== "").map((question, index) => {
                if (answers[index].trim() === '') {
                    isAnswerEmpty = true;
                    setAlert({ 
                        show: true, 
                        message: 'Por favor, não deixe nenhum campo vazio.',
                        type: 'not ok',
                        severity: 'warning'  
                    })
                    setLoading(false)
                }
                let newObj = {}
                newObj[question] = answers[index]
                return newObj
            })
            
            const structured_user_answers = Object.assign({}, ...array_user_answers);
            
            let payload_to_send = {
                id: userInformation.id,
                numDoc: userInformation.numDoc,
                questionsAnswers: structured_user_answers
            }

            if (!isAnswerEmpty) {
                Api.post('/user/verify/questions', payload_to_send).then(res => {
                    const is_authorized = res.status;
                    
                    setToken(res.data.token);
                    if(action === 'signup' && is_authorized === 200){
                        history.push(`/term`);
                    }else if(action === 'forgot' && is_authorized === 200){
                        history.push(`/info`, location.state);
                    }
                }).catch(err => {
                    setAlert({
                        type: 'not ok',
                        severity: 'error',
                        message: err.response.data.errorMessages[0],
                        show: true
                    })
                    setLoading(false);
                });
                }
            }
    }, [action, answers, history, questions, setAlert, setLoading, setToken, userInformation.id, userInformation.numDoc]) 

    const handle_submit = (answers) => {
        setLoading(true)
        const answer_list = []
        for(let [, value] of Object.entries(answers)){
            answer_list.push(value);
        }
        setAnswers(answer_list)
    }

    return (
        <QuestionsContainer>
            <Form onSubmit={handle_submit}>
                <h2>Perguntas de segurança</h2>
                {
                    questions.length > 0 && loading === false
                    ? questions.filter(item => item.trim() !== "").map((item, index) => (
                        <Fragment key={index}>
                            <label key={item}>{item}</label>
                            <Input key={index} name={`question${index}`} type='text' />
                        </Fragment>
                    ))
                    : null
                }
                <Button>
                    Entrar!
                </Button>
            </Form>
        </QuestionsContainer>
    );
}
