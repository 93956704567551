import styled from 'styled-components';

export default styled.div`
    width: 100%;
    height: 100%;

    div{
        margin: 0.5rem 0;
    }

    div:nth-child(1){
        width: 50%;
    }
    div:nth-child(2), div:nth-child(3){
        width: 80%;
    }

    div img{
        width: 100%;
    }

    //flex
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`;