import React from 'react';
import AlestLogo from '../Alest';
import NavMenu from '../NavDropdownMenu';

//styles
import NavContainer from './styles';

export default function Nav() {
  return (
    <NavContainer>
        <div className="right_menu">
            <ul>
                <li><AlestLogo /></li>
                <li><NavMenu /></li>
            </ul>
        </div>
    </NavContainer>
  );
}
