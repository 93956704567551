import React, { useState, useEffect } from 'react';

//resources
import Logos from '../../../components/RowLogos';
import CheckBoxBtn from '../../../components/CheckboxBtn';

//themes
import GlobalStyle from '../../static/css/user_global.js';
import tema from '../../static/css/theme';
 
//contexts
import { ThemeProvider } from 'styled-components';
import AlertContextProvider from '../../../contexts/Alert/AlertContextProvider';

//styles
import TermContainer from './styles';

export default function Home({ Content, sendTo }){

    const [ userTheme, setUserTheme ] = useState(tema);
    
    useEffect(() => {
        if(!!sessionStorage.getItem('@userTheme')){
            let new_data = JSON.parse(sessionStorage.getItem('@userTheme'));
            setUserTheme({ ...userTheme, ...new_data.themes });
        }
    }, []);

    return(
        <ThemeProvider theme={userTheme}>
            <GlobalStyle />
            <AlertContextProvider>
                <Logos />
                <TermContainer>
                    <Content />
                </TermContainer>
                <CheckBoxBtn sendTo={sendTo} />
            </AlertContextProvider>
        </ThemeProvider>
    )
};