import styled from 'styled-components';

export default styled.div`

    width: 100%;
    max-width: 700px;

    .hide_logo{
        opacity: 0;
    }

    .hide_logo div:nth-child(1){
        width: 12%;
        position: relative;
        top: -6px;
    }

    .hide_logo div{
        width: 20%;
    }

        .hide_logo div img{
            width: 100%;
        }

    //flex
    
    .hide_logo{
        display: flex;
        justify-content: space-around;
        align-items:center;
    }

    @media only screen and (max-width: 425px) {
        width: 100%;
        margin-bottom: 5px;

        .hide_logo{
            opacity: 1;
        }
    }
`;