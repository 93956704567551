import React from 'react';
import Loader from '../Loader';
import RowLogos from '../RowLogos';

//resources
import Logos from '../ColumnLogos';
import Alert from '../Alert';

//styles
import PaperContainer from './styles';

export default function Paper({ children }) {
    return (
        <>
            <Alert />
            <Loader />
            <RowLogos />
            <PaperContainer>
                <div className='left_content'>
                    <Logos />
                </div>
                <div className='right_content'>
                    {children}
                </div>
            </PaperContainer>
        </>
    )
}
