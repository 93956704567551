import React from 'react';

import BatchTableContainer from './styles';

export default function BatchTable({Thead, Tbody}) {
    
    return (
        <BatchTableContainer>
            <div className='table'>
                <Thead />
                <Tbody />
            </div>
        </BatchTableContainer>
    );
}
