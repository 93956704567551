import styled from 'styled-components';

export default styled.div`
    width: 35%;
    background: rgba(255, 255, 255, .5);
    padding: 2px;
    margin-top: 5px;
    border-radius: 3px;

    span{
        font-size: 1.5rem;
    }

    //flex

    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 425px) {
        width: 80%;
        margin-bottom: 5px;
    }
`;