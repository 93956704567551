import React, { useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

//contexts
import UserContext from '../../contexts/UserGlobalContext/UserContext';

export default function Loader() {

    const { loading } = useContext(UserContext);

    let isLoading = loading === true ? 1 : 0

    return (
        <div style={{position: 'absolute', top: 0, width: '100%', opacity: isLoading}}>
            <LinearProgress variant="query" />
        </div>
    );
}
