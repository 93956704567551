import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import LoginForm from '../../sub_pages/Login';

//styles
import Theme from '../../static/css/theme';
import LoginContainer from './styles';
import GlobalStyle from '../../static/css/admin_global';

//contexts
import AdminGlobalContext from '../../../contexts/AdminGlobalContext/GlobalContext';
import AlertContextProvider from '../../../contexts/Alert/AlertContextProvider';

export default function Login() {

    const { theme } = useContext(AdminGlobalContext);

    return (
        <ThemeProvider theme={ theme ? Theme.light : Theme.dark }>
            <GlobalStyle />
            <AlertContextProvider>
                <LoginContainer>
                    <div className={'login_box'}>
                        <LoginForm />   
                    </div>
                </LoginContainer>
            </AlertContextProvider>
        </ThemeProvider>
    );
}
