import React from 'react';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton'

import SendContainer from './styles';

export default function SendInput({ label, name }) {
    return (
        <SendContainer>
            <IconButton color='inherit' size='small'>
                <label htmlFor={name}>
                    { label !== false ? <p>Enviar</p> : null }
                    <SendIcon fontSize='large'/>
                </label>
                <input id={name} style={{display: 'none'}} type='submit'/>
            </IconButton>
        </SendContainer>
    );
}
