import React, { useState, useContext, useRef } from 'react';
import { Form } from '@unform/web';
import Cookies from 'js-cookie';

//services
import Api from '../../../service/api';

//contexts
import AlertContext from '../../../contexts/Alert/AlertContext';

//components
import Input from '../../../components/Input';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ClearIcon from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/Remove';

//styles
import UserContainer from './styles';

export default function CreateUser() {
    
    const token = Cookies.get('@user');

    const { setAlert } = useContext(AlertContext);

    const formRef = useRef(null);

    const [ newQuestions, setNewQuestions ] = useState([]);

    const handle_submit = async (payload, { reset }) => {
        try{

            await Api.post('/admin/users', payload, { headers: { Authorization: `Bearer ${token}` } });
            
            setAlert({
                type: 'ok',
                severity: 'success',
                message: 'Ação efetuada com sucesso.',
                show: true
            });
            
            reset();
            
        }catch(err){
            
            setAlert({
                type: 'error',
                severity: 'error',
                message: "Não foi possível completar esta ação.",
                show: true
            })

        }
    }

    const add_input = () => {
        if(newQuestions.length < 2){
            setNewQuestions([...newQuestions, 1])
        }
    }
    
    const minus_input = () => {
        let newQuestionsList = newQuestions.slice();
        newQuestionsList.shift();
        setNewQuestions(newQuestionsList);
    }

    const handle_clear = () => {
        formRef.current.reset();
        setAlert({
            type: 'ok',
            severity: 'success',
            message: 'Campos restaurados com sucesso.',
            show: true
        })
    }
    
    const make_submit = () => {
        formRef.current.submitForm();
    }

    return (
        <UserContainer>
            <Form onSubmit={handle_submit} ref={formRef} >
                <header>
                    <h2>Criação Única</h2>
                    <div className='add_holder'>
                        <label htmlFor='clear_button'>Limpar</label>
                        <IconButton id='clear_button' size='small' color='inherit' onClick={handle_clear}>
                            <ClearIcon fontSize='large' />
                        </IconButton>
                        <label htmlFor='submit_button'>Adicionar</label>
                        <IconButton id='submit_button' size='small' color='inherit' onClick={make_submit}>
                            <ArrowUpwardIcon fontSize='large' />
                        </IconButton>
                    </div>
                </header>
                <div className='content_holder'>
                    <div className='first_section'>
                        <h3>Informações gerais</h3>
                        <label>Nome:</label>
                        <Input name='name' required />
                        <label>Matricula:</label>
                        <Input name='id' required />
                        <label>Documento:</label> 
                        <Input name='num_doc' required />
                        <label>Cargo:</label>
                        <Input name='jobTitle' required />
                        <label>E-mail:</label>
                        <Input name='email' />
                        <label>Telefone: </label>
                        <Input name='phoneNumber' />
                        <label>Localidade/Cidade:</label>
                        <Input name='locality' />
                        <label>Departamento:</label>
                        <Input name='department' />
                        <label>Organização:</label>
                        <Input name='organization' />
                        <label>Divisão:</label>
                        <Input name='division' />
                    </div>
                    <div className='second_section'>
                        <h3>Informações de autenticação</h3>
                        <label>
                            <p>Perguntas</p>
                            <div>
                                <IconButton size='small' color='inherit' onClick={minus_input}>
                                    <RemoveIcon />
                                </IconButton>
                                <IconButton size='small' color='inherit' onClick={add_input}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </label>
                        <Input name='questions.0' required />
                        <Input name='questions.1' required />
                        <Input name='questions.2' required />
                        {
                            newQuestions.map((item, index) => (
                                <Input key={`${index}_${item}`} name={`questions.${index + 3}`} />
                            ))
                        }
                        <label>
                            <p>Respostas</p>
                            <div>
                                <IconButton size='small' color='inherit' onClick={minus_input}>
                                    <RemoveIcon />
                                </IconButton>
                                <IconButton size='small' color='inherit' onClick={add_input}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </label>
                        <Input name='answers.0' required />
                        <Input name='answers.1' required />
                        <Input name='answers.2' required />
                        {
                            newQuestions.map((item, index) => (
                                <Input key={`${index}_${item}`} name={`answers.${index + 3}`} />
                            ))
                        }
                    </div>
                </div>
            </Form>
        </UserContainer>
    );
}
