import React, { useContext } from 'react';
import { Form } from '@unform/web';
import Cookies from 'js-cookie';

//components
import Card from '../../../components/Card';
import BatchTable from '../../../components/BatchTable';
import FileInput from '../../../components/FileInput';
import AttachmentIcon from '@material-ui/icons/Attachment';
import SendInput from '../../../components/SendInput';
import Check from '../../../components/Check'

//services
import Api from '../../../service/api';

//contexts
import AlertContext from '../../../contexts/Alert/AlertContext';

//styles
import BatchUserContainer from './styles';

//tools
import { response_translate } from '../../../Tools/responseHandler';

export default function BatchUser() {

    const token = Cookies.get('@user')

    const { setAlert } = useContext(AlertContext);

    const LeftThead = () => {
        return(
            <div className='left thead'>
                <div className='tr'>
                    <div className='th'>
                        Método
                    </div>
                    <div className='th'>
                        Enviar e-mail de convite?	
                    </div>
                    <div className='th'>
                        Anexar CSV	(máx. 2MB)
                    </div>
                    <div className='th'>
                        Enviar	
                    </div>
                </div>
            </div>
        );
    }

    const handle_submit = async (payload) => {
        let data = new FormData();
        // eslint-disable-next-line no-unused-vars
        var res;
        const options = { headers:{ Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } };
        const method = Object.keys(payload)[0]
        
        const file = new Blob(payload[method].file, { type: 'text/csv' })
        
        if (file.size > 2097152) {
            setAlert({
                type: 'error',
                severity: 'error',
                message: "O csv ultrapassa o limite de 2 MB",
                show: true
            });
        } else {
            data.append('sheet', file);
            data.append('action', method)
        
            try{
                switch(method){
                    case 'creation':
                        res = await Api.post('/admin/csv', data, options);
                        setAlert({
                            type: 'ok',
                            severity: 'success',
                            message: "Upload realizado com sucesso.",
                            show: true
                        });
                        break
                    case 'update':
                        res = await Api.put('/admin/csv', data, options);
                        setAlert({
                            type: 'ok',
                            severity: 'success',
                            message: "Upload realizado com sucesso.",
                            show: true
                        });
                        break
                    case 'suspention':
                        let new_options = options;
                        new_options['data'] = data;
                        res = await Api.delete('/admin/csv', new_options);
                        setAlert({
                            type: 'ok',
                            severity: 'success',
                            message: "Upload realizado com sucesso.",
                            show: true
                        });
                        break
                    case 'activation':
                        res = await Api.put('/admin/csv', data, options);
                        setAlert({
                            type: 'ok',
                            severity: 'success',
                            message: "Upload realizado com sucesso.",
                            show: true
                        });
                        break
                    default:
                        break
                }
            }catch(err){
                setAlert({
                    type: 'error',
                    severity: 'error',
                    message: "Estes usuários já podem ter sido criados ou o arquivo está com falha. Por favor, verifique",
                    show: true
                });
            }    
        }
        
    }

    const LeftTbody = () => {
        return(
            <div className='left tbody'>
                <div className='tr'>
                    <Form onSubmit={handle_submit}>
                        <div className='td'>Criação</div>
                        <div className='td'><Check name='creation.invitation' /></div>
                        <div className='td'><FileInput name='creation.file' showPreview={true} fileType={".csv"} label={false}><AttachmentIcon fontSize='large' /></FileInput></div>
                        <div className='td'><SendInput label={false} name='submit_creation' /></div>
                    </Form>
                </div>
                {/* <div className='tr'>
                    <Form onSubmit={handle_submit}>
                        <div className='td'>Atualização</div>
                        <div className='td'></div>
                        <div className='td'><FileInput name='update.file' label={false}><AttachmentIcon fontSize='large' /></FileInput></div>
                        <div className='td'><SendInput label={false} name='submit_update'/></div>
                    </Form>
                </div> */}
                <div className='tr'>
                    <Form onSubmit={handle_submit}>
                        <div className='td'>Suspensão</div>
                        <div className='td'></div>
                        <div className='td'><FileInput name='suspention.file' showPreview={true} fileType={".csv"} label={false}><AttachmentIcon fontSize='large' /></FileInput></div>
                        <div className='td'><SendInput label={false} name='submit_suspension'/></div>
                    </Form>
                </div>
                <div className='tr'>
                    <Form onSubmit={handle_submit}>
                        <div className='td'>Ativação</div>
                        <div className='td'></div>
                        <div className='td'><FileInput name='activation.file' showPreview={true} fileType={".csv"} label={false}><AttachmentIcon fontSize='large' /></FileInput></div>
                        <div className='td'><SendInput label={false} name='submit_activation'/></div>
                    </Form>
                </div>
            </div>
        );
    }

    const RightThead = () => {
        return(
            <div className='right thead'>
                <div className='tr'>
                    <div className='th'>Modelo</div>
                    <div className='th'>Download</div>
                </div>
            </div>
            
        );
    }

    const RightTbody = () => {
        return(
            <div className='right tbody'>
                <div className='tr'>
                    <div className='td'>Criação</div>
                    <div className='td'>
                        <a download href="https://storage.googleapis.com/csv-models/creation.csv">
                            Download
                        </a>
                    </div>
                </div>
                <div className='tr'>
                    <div className='td'>Suspensão</div>
                    <div className='td'>
                        <a download href="https://storage.googleapis.com/csv-models/suspension.csv">
                            Download
                        </a>
                    </div>
                </div>
                <div className='tr'>
                    <div className='td'>Ativação</div>
                    <div className='td'>
                        <a download href="https://storage.googleapis.com/csv-models/activation.csv">
                            Download
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <BatchUserContainer>
            <div className='left_content'>
                <h2>Alterações em massa</h2>
                <Card width={'100%'} className='update'>
                    <BatchTable Thead={LeftThead} Tbody={LeftTbody} />
                </Card>
            </div>
            <div className='right_content'>
                <h2>Modelos para download</h2>
                <Card Card width={'100%'} className='models'>
                    <BatchTable Thead={RightThead} Tbody={RightTbody} />
                </Card>
            </div>
        </BatchUserContainer>
    );
}
