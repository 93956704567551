import styled from 'styled-components';

export default styled.div`
    width: 100%;
    height: 100%;

    main{
        width: 100%;
        height: calc(100vh - 56px);
    }

    //flex

    display: flex;
    flex-direction: column;

    main{
        display: flex;
    }
`;