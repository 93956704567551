import styled from "styled-components";

export default styled.div`
    width: 100%;

    .table{
        width: 100%;
    }

    .thead{
        text-align: left;
        width: 100%;
    }

        .thead .tr{
            width: 100%;
        }

            .left .tr .th{
                width: 25%;
            }

            .right .tr .th{
                width: 50%;
            }

            .thead .tr .th{
                padding: 3px;
                background: ${props => props.theme.hover};
                font-size: 1.3rem;
                margin-right: 3px;
            }

    .tbody{
        width: 100%;
    }

        .tbody .tr, .tbody .tr form{
            width: 100%;
        }

            .left .tr .td{
                width: 25%;
            }

            .right .tr .td{
                width: 50%;
            }

            .tbody .tr .td{
                padding: 3px;
                font-size: 1rem;
                border-bottom: 1px solid ${props => props.theme.hover};
            }


    //flex
    .thead{
        display: flex;
        align-items: center;
    }

        .thead .tr{
            display: flex;
        }

        .thead .tr .th{
            display: flex;
            align-items: center;
        }
    
    .tbody{
        display: flex;
        flex-direction: column;
    }

        .tbody .tr{
            display: flex;
        }

            .tbody .tr form{
                display: flex;
            }

            .tbody .tr .td{
                display: flex;
                flex-grow: 1;
                margin: 3px;
                align-items: center;
            }

`;