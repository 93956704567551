import styled from 'styled-components';

export default styled.div`

    fill: ${props => props.theme.assets.logo};
    width: 100px;
    height: 35px;

    svg{
        width: 100%;
        height: 100%;
    }
`;