import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

//styles
import ChartContainer from './styles';

function Chart({ data }){

  return (
    <ChartContainer>
      <ResponsiveContainer width='99%'>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 15, right: 30, left: 20, bottom: 5,
            }}
          >
            <XAxis dataKey="Date.value" />
            <YAxis />
            <Tooltip />
            <Line type="linear" dataKey="totalClaim" activeDot={{ r: 8 }} />
          </LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

export default Chart;