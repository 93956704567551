import React from 'react';

import VideoContainer  from './styles';

export default function GuideVideo({ uri }) {
    return(
        <VideoContainer>
            <video src={uri} width='100%' height='100%' controls>
                Seu navegador não suporta esta tag de vídeo
            </video>
        </VideoContainer>
    );
};