import React from 'react';

//styles
import LogosContainer from './styles.js';

//resources
import alest from '../../user/static/images/logos/alestlogo.svg';
import workplace from '../../user/static/images/logos/workplace.png';

export default function Logos() {

  const cliente = !!sessionStorage.getItem('@userTheme') ? JSON.parse(sessionStorage.getItem('@userTheme')) : undefined;

  return (
    <LogosContainer>
      <div className="hide_logo">
          <div>
              <img src={alest} alt='alest logo' />
          </div>
          {
            !!cliente
            ? <div><img src={cliente.logo} alt='cliente logo' /></div>
            : null
          }
          <div>
              <img src={workplace} alt='workplace logo' />
          </div>
        </div>
    </LogosContainer>
  );
}
