import React, { useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//contexts
import AlertContext from '../../contexts/Alert/AlertContext';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomizedSnackbars() {

    const { showing, setAlert } = useContext(AlertContext);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({ ...showing, show: false });
    };

    return (
        <Snackbar open={showing.show} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={showing.severity}>
                {showing.message}
            </Alert>
        </Snackbar>
    );
}
