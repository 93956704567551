import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { response_translate } from '../../../Tools/responseHandler';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

//components
import Table from '../../../components/Table';

//services
import Api from '../../../service/api';

//contexts
import AlertContext from '../../../contexts/Alert/AlertContext';

//styles
import UsersContainer from './styles';

export default function Users() {

    const token = Cookies.get('@user');

    const { setAlert } = useContext(AlertContext);

    const [ rows, setRows ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ pagination, setPagination ] = useState(1);
    const [ query, setQuery ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const [ update, forceUpdate ] = useState();
    
    const header = [ 'ID', 'Documento', 'Nome', 'Divisao', 'Departamento', 'Reivindicado', 'Status' ]
    const width = Math.ceil(100/header.length)

    useEffect(() => {
        setLoading(true);
        Api.get('/admin/users', { headers: { Authorization: `Bearer ${token}` }, params: { page: pagination } }).then(res => {
            setRows(res.data.paginated);
            setTotalPages(res.data.totalPages);
            setQuery('');
            setLoading(false);
        }).catch(err => {
            setAlert({
                type: 'error',
                severity: 'error',
                message: response_translate(err.response),
                show: true
            });
            setLoading(false);
        });
        
    }, [ pagination, setAlert, token, update ])

    useEffect(() => {
        let row = rows.filter(row => row.name.toLowerCase().includes(query.toLowerCase()) || row.id.includes(query));
        if(row.length === 0 && query !== ''){
            Api.get(`/admin/users/${query}`, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
                let user = { 
                    id: res.data.cpData.id, 
                    numDoc: res.data.cpData.num_doc,
                    name: res.data.wpData.core.name,
                    division: res.data.wpData.enterprise.division,
                    department: res.data.wpData.enterprise.department,
                    claimed: res.data.wpData.accountStatusDetails.claimed,
                    active: res.data.wpData.core.active
                }
                setRows([user])
            }).catch(err => {
                setAlert({
                    type: 'error',
                    severity: 'error',
                    message: `Usuário com ID: ${query} não foi encontrado.`,
                    show: true
                });
                setQuery('');
            });
        }else{
            setRows(row);
        }
    }, [ query, setAlert, token ])

    const Thead = () => {
        return(
            <div className='thead' style={{opacity: loading === true ? '.3' : 1}}>
                <div className='tr'>
                    <div className='th' style={{width: `${width}%`}} key={0}>Índice</div>
                    {
                        header.map((header, index) => (
                            <div className='th' style={{width: `${width}%`}} key={index + 1}>{header}</div>
                        ))
                    }
                    <div className='th' style={{width: `${width}%`}} key={7}>Ações</div>
                </div>
            </div>
        );
    }

    const handle_activation = ({ numDoc,...payload }) => {
        setLoading(true);
        Api.put('/admin/users', {...payload, num_doc: numDoc, active: true}, { headers: { Authorization: `Bearer ${token}` } }).catch(err => {
            setLoading(false);
            setAlert({
                type: 'error',
                severity: 'error',
                message: response_translate(err.response.data.msg),
                show: true
            })
        });
        forceUpdate(Math.random());
    }

    const handle_desativation = async ({ numDoc,...payload }) => {
        setLoading(true);
        await Api.put('/admin/users', {...payload, num_doc: numDoc, active: false}, { headers: { Authorization: `Bearer ${token}` } }).catch(err => {
            setLoading(false);
            setAlert({
                type: 'error',
                severity: 'error',
                message: response_translate(err.response.data.msg),
                show: true
            });
        });
        forceUpdate(Math.random());
    }

    const Tbody = ({ handle_click }) => {
        return(
            <div className='tbody' style={{opacity: loading === true ? '.3' : 1}}>
                {
                    rows.map((row, index) => (
                        <div className='tr' key={index}>
                            <div className='td' style={{width: `${width}%`}}>{((pagination * 10) - 10) + index}</div>
                            
                            <div className='td' style={{width: `${width}%`}}>{ row.id }</div>
                            <div className='td' style={{width: `${width}%`}}>{ row.numDoc }</div>
                            <div className='td' style={{width: `${width}%`}}>{ row.name }</div>
                            <div className='td' style={{width: `${width}%`}}>{ row.division }</div>
                            <div className='td' style={{width: `${width}%`}}>{ row.department }</div>
                            <div className='td' style={{width: `${width}%`}}>{ row.claimed ? "Sim" : "Não" }</div>
                            <div className='td' style={{width: `${width}%`}}>{ row.active ? "Ativado" : "Desativado" }</div>

                            <div className='td' style={{width: `${width}%`}}>
                                <IconButton size="small" color="inherit" onClick={() => handle_activation(row)}><CheckCircleOutlineIcon fontSize="large"/></IconButton>
                                <IconButton size="small" color="inherit" onClick={() => handle_desativation(row)}><BlockIcon fontSize="large"/></IconButton>
                                <IconButton size="small" color="inherit" onClick={() => handle_click(row)}><EditIcon fontSize="large"/></IconButton>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }

    return (
        <UsersContainer>
            <Table
                Tbody={Tbody}
                Thead={Thead}
                setQuery={setQuery}
                pagination_config={{ totalPages, setPagination }}
                searchInputPlaceholder='Pesquise por ID/Matricula'
                forceUpdate={forceUpdate}
                paginate
                is_clickable
            />
        </UsersContainer>
    );
}
