import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';

//contexts
import AuthProvider from './contexts/Auth/AuthProvider';
import TokenProvider from './contexts/Token/TokenProvider';

ReactDOM.render(
    <AuthProvider>
        <TokenProvider>
            <Routes />
        </TokenProvider>
    </AuthProvider>, 
    document.getElementById('root')
);