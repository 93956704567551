import React, { useState } from 'react';

//context
import AlertContext from './AlertContext';

export default function UserGlobalContext({ children }) {

    const [ showing, setAlert ] = useState({
        type: 'error',
        severity: 'error',
        message: 'This is an error message!',
        show: false,
    });

    return (
        <AlertContext.Provider 
            value={{
                showing, setAlert
            }}>
            {children}
        </AlertContext.Provider>
    );
}
