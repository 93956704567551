import React from 'react';

import ButtonContainer from './styles.js';

export default function Button({ children, ...rest }) {

    return (
        <ButtonContainer>
            <button className="button" {...rest}>
                {children}
            </button>
        </ButtonContainer>
    );
}
