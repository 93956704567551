import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *, *:before, *:after{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: ${props => props.theme.border};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.primary};
        border-left: .5px solid #333;
        border-right: .5px solid #333;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.hover};
    }

    html, body, #root{
        font-family: 'Roboto', Arial;
        font-size: 10px;
        width: 100%;
        height: 100%;
        color: ${props => props.theme.text};
        background: ${props => props.theme.primary};
        overflow: hidden;
    }
`;