import React from 'react';

import CardContainer from './styles';

export default function Card({ children, width, height, ...rest }) {
    return (
        <CardContainer width={width} height={height} {...rest}>
            { children }
        </CardContainer>
    );
}
