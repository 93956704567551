import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import Input from '../../../components/Input';
import Api from '../../../service/api';

//resources
import Button from '../../../components/Button';

//styles
import LoginContainer from './styles.js';

//contexts
import AuthContext from '../../../contexts/Auth/AuthContext';
import { ThemeContext } from 'styled-components';
import AlertContext from '../../../contexts/Alert/AlertContext';
import UserGlobalContext from '../../../contexts/UserGlobalContext/UserContext';

export default function Login({ computedMatch }) {

    const { action } = computedMatch.params;
    const history = useHistory();

    const { label, placeholder } = useContext(ThemeContext);
    const { setUserAuth, setUserInformation } = useContext(AuthContext);
    const { setAlert } = useContext(AlertContext);
    const { setLoading } = useContext(UserGlobalContext);
    
    const handle_submit = ({ id, numDoc }) => {
        setLoading(true);
        const normalizedId = id.trim();
        const normalizedNumDoc = numDoc.trim();
        if(normalizedId === "" || normalizedNumDoc === ""){
            setAlert({ 
               show: true, 
               message: 'Por favor, não deixe nenhum campo vazio.',
               type: 'not ok',
               severity: 'warning'  
            })
            setLoading(false)
        }else{
            Api.post('user/verify/auth', { id, numDoc }).then(res => {
                if(res.status === 200){
                    setLoading(false);
                    setUserAuth(true);
                    setUserInformation({ id, numDoc })
                    history.push({ pathname: `/questions/${action}`, state: { method: action } });
                }
            }).catch(err => {
                setLoading(false);
                setAlert({
                   type: 'not ok',
                   severity: 'error',
                   message: err.response.data.msg,
                   show: true
                });
            });
        }
    }
        
    return (
        <LoginContainer>
            <Form onSubmit={handle_submit}>
                <h2>Login</h2>
                <label>{label.id}</label>
                <Input name='id' placeholder={placeholder.id} />
                <label>{label.num_doc}</label>
                <Input name='numDoc' placeholder={placeholder.num_doc} />
                <Button>
                    Entrar!
                </Button>
            </Form>
        </LoginContainer>
    );
}
