import styled from 'styled-components';

export default styled.div`
    width: 100%;
    background: ${props => props.theme.background};
    border-radius: 5px;
    padding: 20px;

    form{
        width: 100%;
        max-width: 800px;
        background: ${props => props.theme.primary};
    }

    header{
        background: ${props => props.theme.complementary};
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 5px 20px;
        color: ${props => props.theme.primary};
        font-size: 1.5rem;
    }

        .add_holder label{
            margin-right: .5rem;
            font-size: 1rem;
        }

        .first_section{
            width: 50%;
            margin-right: .5rem;
            padding: 20px;
        }

            .first_section input{
                width: 100%;
                height: 25px;
                margin-bottom: .5rem;
                background: ${props => props.theme.input.background};
                border: none;
                padding: 10px;
            }
            .first_section h3{
                margin-bottom: .6rem;
            }

        .second_section{
            color: ${props => props.theme.primary};
            padding: 20px;
            width: 50%;
            background: ${props => props.theme.complementary};
        }

            .second_section input{
                width: 100%;
                height: 25px;
                margin-bottom: .5rem;
                background: ${props => props.theme.input.background};
                border: none;
                padding: 10px;
            }

            .second_section h3{
                margin-bottom: .6rem;
            }

            .second_section label{
                cursor: pointer;
                margin-bottom: .5rem;
            }

    //flex
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
    .content_holder{
        display: flex;
    }

    form header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .second_section label{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;